export const indication = [
  {
    id: 1,
    text: 'Dores lombar - Lombalgias',

  },
  {
    id: 2,
    text: 'Hérnia de disco',
  },
  {
    id: 3,
    text: 'Dores na coluna cervical - Cervicalgia',
  },
  {
    id: 4,
    text: 'Dor Ciática',
  },
  {
    id: 5,
    text: 'Dores na coluna dorsal - Dorsalgia',
  },
  {
    id: 6,
    text: 'Dores no ombro',
  },
  {
    id: 7,
    text: 'Dor muscular e ligamentar',
  },
  {
    id: 8,
    text: 'Distúrbios Temporomandibular - ATM',
  },
  {
    id: 9,
    text: 'Dores de cabeça / Enxaqueca- Cefaleia tensional',
  },
  {
    id: 10,
    text: 'Intestino preso - Constipação',
  },
  {
    id: 11,
    text: 'Dores no joelhos',
  },
  {
    id: 12,
    text: 'Bursite',
  },
  {
    id: 13,
    text: 'Fibromialgia',
  },
  {
    id: 14,
    text: 'Tendinite',
  },
  {
    id: 15,
    text: 'Refluxo',
  },
  {
    id: 16,
    text: 'Bruxismo',
  },
]
