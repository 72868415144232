import React from 'react'
import PropTypes from 'prop-types'
import { SeparatorLine } from 'components/Separator/styles'

export const Separator = ({ color, width, mr, ml }) => {
  return <SeparatorLine color={color} width={width} ml={ml} mr={mr} />
}

Separator.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  ml: PropTypes.string,
  mr: PropTypes.string,
}
