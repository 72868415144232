import React from 'react'
import { CardImageRight } from 'components/Card/styles'
import PropTypes from 'prop-types'

export const CardRightBorder = ({ children, color, width, altImage, src, height, heightImage, widthImage, float }) => {
  return (
    <CardImageRight color={color} width={width} height={height} float={float}>
      {children}
    </CardImageRight>
  )
}

CardRightBorder.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  widthImage: PropTypes.number,
  heightImage: PropTypes.number,
  src: PropTypes.string,
  altImage: PropTypes.string,
  float: PropTypes.string,
}
