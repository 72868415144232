import React from 'react'
import Slider from 'react-slick'
import { token } from 'styles/theme'
import { Container, Text, TextLabel, CardSlider } from './styles'
import { Separator } from 'components/Separator'
import { Subtitle } from 'components/Subtitle'
import { Title } from 'components/Title'
import { CardImage } from 'components/Card/CardImage'
import { getAllArticles } from 'lib/articles'
import { Heading2 } from 'components/Title/Headings/Heading2'
import Link from 'next/link'
import { isMobile } from 'react-device-detect'

export const Blog = () => {
  const articles = getAllArticles()
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    <Container>
      <div>
        <Subtitle
          size={15}
          color={token.color.darkGrey}
          align="center"
          width={100}
          fw="500"
          family="'Open Sans', sans-serif"
          lineHeight="22.5"
        >
          BLOG
        </Subtitle>
        {isMobile ? (
          <Title
            color={token.color.darkGrey}
            size="26"
            width="100"
            align="center"
            id="como-funciona-a-consulta"
            lineHeight="32"
            fw="500"
            mb="10"
            family="Roboto, sans-serif"
          >
            Últimos artigos <br /> publicados
          </Title>
        ) : (
          <Title
            color={token.color.darkGrey}
            size="36"
            width="100"
            align="center"
            id="como-funciona-a-consulta"
            lineHeight="36"
            fw="500"
            mb="10"
            family="Roboto, sans-serif"
          >
            Últimos artigos <br /> publicados
          </Title>
        )}
        <Separator color={token.color.darkSecondary} width={85} ml="auto" mr="auto" />
      </div>
      <Slider {...settings}>
        {articles.map(article => {
          return (
            // eslint-disable-next-line react/jsx-key
            <CardSlider>
              <CardImage
                color={token.color.white}
                width={95}
                height={435}
                src={article.cover}
                altImage={article.slug}
                widthImage={335}
                heightImage={174.5}
              >
                <div>
                  <Heading2
                    color={token.color.darkGrey}
                    size="24"
                    lineHeight="28"
                    pd="41px 0 0"
                    align="left"
                    width="90"
                  >
                    {article.title}
                  </Heading2>
                  <Text>{article.excerpt}</Text>
                  <TextLabel>
                    <Link href={`/artigos/${article.slug}`}>Ler mais</Link>
                  </TextLabel>
                </div>
              </CardImage>
            </CardSlider>
          )
        })}
      </Slider>
    </Container>
  )
}
