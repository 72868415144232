import styled from 'styled-components'
import { token } from 'styles/theme'

export const Container = styled.div`
  background-color: ${token.color.white};
  margin: 50px 0 0;
  height: 700px;
  text-align: left;
  padding: 0 16px;

  .slick-next {
    right: 125px;
    bottom: -35px;
    top: 455px;
  }

  .slick-next:before {
    content: url('/assets/arrow-right.png');
    width: 52px;
    height: 51px;
  }

  .slick-prev:before {
    content: url('/assets/arrow-left.png');
    width: 52px;
    height: 51px;
  }

  .slick-prev {
    left: 100px;
    bottom: -35px;
    top: 455px;
  }

  @media (min-width: 1200px) {
    height: 720px;
    padding: 0 calc((100% - 1280px) / 2);
    .slick-next {
      right: 549px;
      bottom: -35px;
      top: 455px;
    }

    .slick-prev {
      left: 608px;
      bottom: -35px;
      top: 455px;
    }
  }

  @media (min-width: 1900px) {
    height: 720px;

    .slick-next {
      right: 588px;
    }

    .slick-prev {
      left: 488px;
    }
  }
`
export const Text = styled.p`
  font-weight: 400;
  font-size: 17px;
  text-align: left;
  line-height: 22px;
  padding-top: 17px;
  margin-top: 0;
  width: 90%;
`
export const TextLabel = styled.div`
  a {
    font-weight: 500;
    font-size: 14px;
    color: ${token.color.darkSecondary};
    position: absolute;
    bottom: 12px;
    text-decoration: underline;
  }

  @media (min-width: 1200px) {
    a {
      bottom: 12px;
    }
  }
`

export const CardSlider = styled.div``
