import React from 'react'
import { HeadingText } from 'components/Title/styles'
import PropTypes from 'prop-types'

export const Heading2 = ({ family, children, color, size, width, mb, mt, id, lineHeight, align, pd }) => {
  return (
    <HeadingText
      family={family}
      color={color}
      size={size}
      width={width}
      mt={mt}
      mb={mb}
      id={id}
      lineHeight={lineHeight}
      align={align}
      pd={pd}
    >
      {children}
    </HeadingText>
  )
}

Heading2.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  width: PropTypes.string,
  mt: PropTypes.number,
  mb: PropTypes.number,
  id: PropTypes.string,
  lineHeight: PropTypes.string,
  align: PropTypes.string,
  family: PropTypes.string,
  pd: PropTypes.string,
}
