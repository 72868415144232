export const interviews = [
  {
    id: 1,
    text: 'Aqui são feitas perguntas sobre o estado de saúde geral do paciente e informações específicas sobre sua queixa principal.',
    title: 'Entrevista',
  },
  {
    id: 2,
    text: 'São realizados testes que direcionam o tratamento.',
    title: 'Avaliação',
  },
  {
    id: 3,
    text: 'Aplica-se técnicas voltadas para o ganho de qualidade de vida do paciente.',
    title: 'Tratamento',
  },
  {
    id: 4,
    text: 'Indicações de exercícios, bons hábitos e frequência de retorno ao consultório.',
    title: 'Recomendações',
  },
]
