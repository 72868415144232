import styled from 'styled-components'

export const LinkWhatsapp = styled.a`
  position: fixed;
  right: 22px;
  bottom: 14px;
  z-index: 2;
  @media (min-width: 992px) {
    right: 35px;
    bottom: 21px;
  }
  @media (min-width: 1900px) {
    bottom: 62px;
  }
`
export const ImageIcon = styled.img``
