import styled from 'styled-components'
import { token } from 'styles/theme'

export const Container = styled.div``

export const BookBackground = styled.div`
  background: url('/assets/consulta-coluna.jpeg');
  background-repeat: no-repeat;
  width: 100%;
  height: 123px;
  background-color: ${token.color.secondary};
  color: ${token.color.darkGrey};
  display: flex;
  justify-content: center;
  background-size: cover;
  background-position: center;
  align-items: center;

  @media (min-width: 992px) {
    background-position: 0 -148px;
    height: 347px;
    margin-top: -200px;
  }

  @media (min-width: 1900px) {
    background-position: 0 -207px;
    height: 430px;
    margin-top: 77px;
  }
`
