import styled from 'styled-components'

export const ParagraphText = styled.p`
  font-size: ${props => `${props.size}px`};
  color: ${props => props.color};
  padding: ${props => `${props.pd}px`};
  font-family: 'Open Sans', sans-serif;
  line-height: ${props => (props.lineHeight ? `${props.lineHeight}px` : '22.5px')};
  text-align: ${props => (props.align ? `${props.align}` : 'center')};
  width: ${props => `${props.width}%`};
  font-weight: ${props => (props.fw ? `${props.fw}` : '400')};
  margin-top: ${props => `${props.mt}px`};
  margin-bottom: ${props => `${props.mb}px`};
  margin-left: ${props => (props.ml ? `${props.ml}` : '0px')};
  margin-right: ${props => (props.mr ? `${props.mr}` : '0px')};
  @media (min-width: 600px) {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 1200px) {
    width: ${props => `${props.width}%`};
    text-align: left;
    margin-left: 0;
    margin-right: 0;
  }
`
