import React from 'react'
import { token } from 'styles/theme'
import { Button } from 'components/Button'
import { Title } from 'components/Title'
import { BookBackground, Container } from 'components/BookAnAppointment/styles'
import { isMobile } from 'react-device-detect'

export const BookAnAppointment = () => {
  const callOnWhatsapp = () => {
    window.open('https://www.juliananakashima.com.br/whatsapp?origin=site', '_blank')
  }

  return (
    <Container>
      <BookBackground>
        {isMobile ? (
          //visão mobile
          <div>
            <Title width={100} size={18} mb={0} pd="16px 0" mt="73" align="center" fw="500" lineHeight="20.7">
              Livre-se das dores, <br /> comece agora seu <br /> tratamento!
            </Title>
            <Button
              variant={token.color.primary}
              color={token.color.white}
              ml="auto"
              mr="auto"
              mt="0"
              mb="44"
              width="242"
              height="43"
              onClick={callOnWhatsapp}
            >
              Agendar consulta
            </Button>
          </div>
        ) : (
          //visão desktop
          <div>
            <Title width={100} size={42} mb={0} pd="16px 0" mt={0} align="center" fw="500" lineHeight="49.98">
              Livre-se das dores, <br /> comece agora seu tratamento!
            </Title>
            <Button
              variant={token.color.whatsAppGreen}
              color={token.color.white}
              ml="auto"
              mr="auto"
              mt="33"
              mb="44"
              onClick={callOnWhatsapp}
            >
              Agendar consulta
            </Button>
          </div>
        )}
      </BookBackground>
    </Container>
  )
}
