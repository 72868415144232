import React, { useState } from 'react'
import { Container, Menu, MenuItem, MenuFixed, MenuButton, ImageHeader } from 'components/Header/styles'
import { isMobile } from 'react-device-detect'
import { token } from 'styles/theme'

export const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const handleMenu = () => setMenuOpen(!menuOpen)
  const closeMobileMenu = () => setMenuOpen(false)

  const smoothscroll = link => {
    location.href = link
  }

  return (
    <MenuFixed>
      <Container>
        <Menu className={menuOpen ? 'nav-options active' : 'nav-options'}>
          {
            //visão somente mobile
            isMobile && (
              <MenuItem className="option logo-menu" onClick={closeMobileMenu}>
                <ImageHeader src="/assets/logo-white.svg" alt="Juliana Nakashima Logo" width={94} height={93} />
              </MenuItem>
            )
          }
          <MenuItem className="option" onClick={closeMobileMenu}>
            <MenuButton
              color={isMobile ? token.color.white : token.color.darkPrimary}
              outline={token.color.white}
              fontSize="15"
              fw="400"
              onClick={() => smoothscroll('#inicio')}
            >
              Início
            </MenuButton>
          </MenuItem>
          <MenuItem className="option" onClick={closeMobileMenu}>
            <MenuButton
              color={isMobile ? token.color.white : token.color.darkPrimary}
              outline={token.color.white}
              fontSize="15"
              fw="400"
              onClick={() => smoothscroll('#osteopatia-e-quiropraxia')}
            >
              Osteopatia e Quiropraxia
            </MenuButton>
          </MenuItem>
          <MenuItem className="option" onClick={closeMobileMenu}>
            <MenuButton
              color={isMobile ? token.color.white : token.color.darkPrimary}
              outline={token.color.white}
              fontSize="15"
              fw="400"
              onClick={() => smoothscroll('#indicacoes-e-tratamentos')}
            >
              Indicações e tratamentos
            </MenuButton>
          </MenuItem>
          <MenuItem className="option" onClick={closeMobileMenu}>
            <MenuButton
              color={isMobile ? token.color.white : token.color.darkPrimary}
              outline={token.color.white}
              fontSize="15"
              fw="400"
              onClick={() => smoothscroll('#formacao-profissional')}
            >
              Formação profissional
            </MenuButton>
          </MenuItem>
          <MenuItem className="option" onClick={closeMobileMenu}>
            <MenuButton
              color={isMobile ? token.color.white : token.color.darkPrimary}
              outline={token.color.white}
              fontSize="15"
              fw="400"
              onClick={() => smoothscroll('#como-funciona-a-consulta')}
            >
              Como funciona a consulta?
            </MenuButton>
          </MenuItem>
          <MenuItem className="option" onClick={closeMobileMenu}>
            <MenuButton
              color={isMobile ? token.color.white : token.color.darkPrimary}
              outline={token.color.white}
              fontSize="15"
              fw="400"
              onClick={() => smoothscroll('#localizacao')}
            >
              Localização
            </MenuButton>
          </MenuItem>
          <MenuItem className="option" onClick={closeMobileMenu}>
            <MenuButton
              color={isMobile ? token.color.white : token.color.darkPrimary}
              outline={token.color.white}
              fontSize="15"
              fw="400"
              onClick={() => smoothscroll('#contatos')}
            >
              Contatos
            </MenuButton>
          </MenuItem>
        </Menu>
        <div className="mobile-menu" onClick={handleMenu}>
          {menuOpen ? (
            //visao mobile
            <ImageHeader src="/assets/close-white.svg" alt="fechar menu" width={20} height={20} />
          ) : (
            //visao desktop
            <ImageHeader src="/assets/menu.svg" alt="fechar menu" width={20} height={20} />
          )}
        </div>
      </Container>
    </MenuFixed>
  )
}
