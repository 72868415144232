import React from 'react'
import { Container, Content, Title, Text, Info, IconImage, InfoDesktop, InfoAddress, Icon } from './styles'
import { isMobile } from 'react-device-detect'

export const ContactInformation = () => {
  return (
    <Content>
      <Container>
        <Info>
          {!isMobile ? (
            <IconImage>
              <Icon src="/assets/icon-phone.png" alt="telefone" width={24} height={24} />
            </IconImage>
          ) : (
            <></>
          )}
          <div>
            <Title>Telefone</Title>
            <Text>11 92017-0738</Text>
          </div>
        </Info>
        <InfoDesktop>|</InfoDesktop>
        <Info>
          {!isMobile ? (
            <IconImage>
              <Icon src="/assets/map-pin.png" alt="endereço" width={24} height={24} />
            </IconImage>
          ) : (
            <></>
          )}
          <InfoAddress>
            <Title>Endereço</Title>
            <Text>Rua Palestina, 359<br />Vila Mascote - São Paulo - SP</Text>
          </InfoAddress>
        </Info>
      </Container>
    </Content>
  )
}
