import React from 'react'
import { SubtitleText } from 'components/Subtitle/styles'
import PropTypes from 'prop-types'

export const Subtitle = ({ children, color, size, width, align, fw, family, id, lineHeight, mb, mt, pd }) => {
  return (
    <SubtitleText
      color={color}
      size={size}
      width={width}
      align={align}
      fw={fw}
      family={family}
      id={id}
      lineHeight={lineHeight}
      mb={mb}
      mt={mt}
      pd={pd}
    >
      {children}
    </SubtitleText>
  )
}

Subtitle.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  width: PropTypes.string,
  align: PropTypes.string,
  fw: PropTypes.string,
  family: PropTypes.string,
  id: PropTypes.string,
  lineHeight: PropTypes.string,
  mb: PropTypes.string,
  mt: PropTypes.string,
  pd: PropTypes.string,
}
