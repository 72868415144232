import styled from 'styled-components'
import { token } from 'styles/theme'

export const Content = styled.div`
  background-color: ${token.color.primary};
  width: 100%;
  height: 99px;
  bottom: -46px;
  position: absolute;
  @media (min-width: 992px) {
    border-radius: 80px;
    height: 113px;
    width: 50%;
    display: grid;
    justify-content: center;
    margin: 0 auto;
    position: absolute;
    bottom: -22px;
    left: 0;
    right: 0;
  }
  @media (min-width: 1900px) {
    bottom: -27px;
    width: 39%;
  }
`
export const Container = styled.div`
  display: block;
  @media (min-width: 992px) {
    display: grid;
    justify-content: center;
    grid-template-columns: 165px 22px 354px;
    grid-gap: 10px;
    align-items: baseline;
  }
`
export const Subtitle = styled.label`
  display: none;
  @media (min-width: 992px) {
    display: block;
    color: ${token.color.success};
    margin: 6px auto 6px;
  }
`
export const Info = styled.div`
  display: flex;
  margin: 0 auto 8px;
  justify-content: center;
  align-items: end;
  color: ${token.color.white};
  &:first-child {
    padding-top: 16px;
  }
  &:second-child {
    display: block;
  }
  @media (min-width: 992px) {
    &:second-child {
      display: block;
    }
  }
`
export const Title = styled.p`
  font-size: 12px;
  display: none;
  @media (min-width: 992px) {
    display: block;
    color: ${token.color.white};
    margin-bottom: 0;
  }
`
export const Text = styled.label`
  font-size: 16px;
  font-family: Open Sans, sans-serif;
  color: ${token.color.white};
`
export const IconImage = styled.div`
  margin-right: 5px;
`

export const InfoDesktop = styled.div`
  display: none;
  @media (min-width: 992px) {
    color: ${token.color.white};
    display: flex;
    justify-content: center;
    align-self: center;
  }
`

export const InfoAddress = styled.div`
  width: 50%;
  text-align: center;
  @media (min-width: 992px) {
    width: 100%;
    text-align: left;
  }
`
export const Icon = styled.img``
