import styled from 'styled-components'

export const Container = styled.div`
  background: linear-gradient(180deg, rgba(235, 240, 243, 0) 0%, rgba(235, 240, 243, 0.0677083) 0.01%, #ebf0f3 100%);
  text-align: center;
  margin: 50px 0 0;
  height: 700px;
  padding: 0 8px;

  .slick-next {
    right: 120px;
    bottom: -35px;
    top: 455px;
  }

  .slick-next:before {
    content: url('/assets/arrow-right.png');
    width: 52px;
    height: 51px;
  }

  .slick-prev:before {
    content: url('/assets/arrow-left.png');
    width: 52px;
    height: 51px;
  }

  .slick-prev {
    left: 100px;
    bottom: -35px;
    top: 455px;
  }

  @media (min-width: 1200px) {
    padding: 0 calc((100% - 1280px) / 2);
    height: 720px;

    .slick-next {
      right: 576px;
      bottom: -35px;
      top: 455px;
    }

    .slick-prev {
      left: 539px;
      bottom: -35px;
      top: 455px;
    }
  }

  @media (min-width: 1900px) {
    height: 740px;

    .slick-next {
      right: 574px;
    }

    .slick-prev {
      left: 500px;
    }
  }
`
export const SubtitleSection = styled.label`
  font-size: 15px;
  text-align: center;
`
export const Text = styled.p`
  font-weight: 400;
  font-size: 16px;
  text-align: left;
  line-height: 22.5px;
  margin: 0 16px;
`
export const TextLabel = styled.label`
  font-weight: 300;
  font-size: 12px;
`
export const Stars = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: left;
  margin: 0 10px;
  width: 100%;
  display: inline-flex;
  justify-content: left;
  span {
    margin: 0 2px !important;
  }
`
export const Patiente = styled.p`
  font-weight: 700;
  font-size: 15px;
  margin-bottom: 0;
  text-align: left;
`
export const UserName = styled.div`
  position: absolute;
  text-align: left;
  bottom: 105px;
  margin: 0 16px;
`
export const CardSlider = styled.div``
export const PrevButton = styled.div`
  background: url('/assets/arrow-left.png');
  width: 52px;
  height: 51px;
`
export const ForwardButton = styled.div`
  background: url('/assets/arrow-right.png');
  width: 52px;
  height: 51px;
`
export const ImageRating = styled.img``
