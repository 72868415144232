import styled from 'styled-components'

export const CardLayout = styled.div`
  background-color: ${props => props.color};
  padding: 16px;
  width: ${props => `${props.width}%`};
  height: ${props => `${props.height}px`};
  border-radius: 20px;
  margin-bottom: ${props => `${props.mb}px`};
  margin-left: ${props => `${props.ml}px`};
  align-content: baseline;
  display: grid;
`

export const CardImageLeft = styled.div`
  background-color: ${props => props.color};
  padding: 16px;
  width: ${props => `${props.width}%`};
  height: ${props => `${props.height}px`};
  border-radius: 20px;
  margin-bottom: ${props => `${props.mb}px`};
  margin-left: ${props => `${props.ml}px`};
`

export const CardImageRight = styled.div`
  background-color: ${props => props.color};
  padding: 16px;
  width: 74%;
  height: 370px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  position: absolute;
  right: 0;
  bottom: -25px;
  order: 3;
  display: none;

  @media (min-width: 1200px) {
    display: block;
    width: ${props => `${props.width}%`};
    height: ${props => `${props.height}px`};
    right: 0;
    top: 150px;
  }
  @media (min-width: 1900px) {
    width: 20%;
    height: 630px;
    top: 131px;
  }
`

export const ImageLayout = styled.div``

export const ImageCard = styled.img``

export const ImageLayoutWBorder = styled.div`
  background: url(${props => `${props.image}`});
  margin: 0 auto;
  background-size: cover;
  width: 90%;
  height: 300px;
  @media (min-width: 1200px) {
    position: absolute;
    top: 180px;
    right: 0;
    width: 30%;
    height: 535px;
    border-top-left-radius: 20px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 20px;
  }
  @media (min-width: 1900px) {
    position: absolute;
    top: 180px;
    right: 0;
    width: 30%;
    height: 535px;
    border-top-left-radius: 20px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 20px;
  }
`

export const ImageLayoutBorder = styled(ImageLayoutWBorder)`
  @media (min-width: 1200px) {
    width: 41%;
    height: 485px;
  }
  @media (min-width: 1900px) {
    width: 33%;
    height: 536px;
  }
`
