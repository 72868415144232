import React from 'react'
import { LinkWhatsapp, ImageIcon } from 'components/Whatsapp/styles'
import { isMobile } from 'react-device-detect'
import { useRouter } from 'next/router';

export const Whatsapp = () => {
  const router = useRouter();

  const GetURL = () =>
  {
    let origin = "site";

    if ('source_campaign' in router.query) 
    {
      origin = router.query.source_campaign;
    }

    let url = "https://www.juliananakashima.com.br/whatsapp?origin="+origin;

    return url;

  }

  return (
    <LinkWhatsapp href={GetURL()} target="_blank">
      {isMobile ? (
        //visão destkop
        <ImageIcon src="/assets/whatsapp.svg" alt="contato no whatsapp" width={40} height={40} />
      ) : (
        //visão destkop
        <ImageIcon src="/assets/whatsapp.svg" alt="contato no whatsapp" width={60} height={60} />
      )}
    </LinkWhatsapp>
  )
}
