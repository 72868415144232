const articles = [
  {
    id: 1,
    cover: '/assets/blog/hernia-de-disco-cirurgia-thumb.jpeg',
    img: '/assets/blog/hernia-de-disco-cirurgia.jpeg',
    theme: 'Artigos',
    title: '90% dos casos de hérnia de disco, não precisam de cirurgias!',
    excerpt: `Na maioria dos casos, a hérnia de disco pode ser tratadas sem cirurgias...`,
    content: `Você sabia que 80% da população sofre de hérnia de disco na região lombar? Esse tipo de lesão também pode acontecer na região cervical e torácia.\r\nNa maioria dos casos, a hérnia de disco pode ser tratada com fisioterapia e apenas 10% dos casos são cirúrgicos.\r\nConfira as dicas para tratamanento de hérnia de disco:\r\nOsteopatia e Quiropraxia;\r\n\r\nMudanças do estilo de vida;\r\nBoa alimentação e hidratação;\r\nPraticar atividades físicas, evitar sedentarismo;\r\nControle do stress e ansiedades.\r\nSe você sofre com dores na coluna ou tem hérnia de disco, agende sua consulta!`,
    slug: `hernia-de-disco-sem-cirurgia`,
  },
  {
    id: 2,
    cover: '/assets/blog/cuidados-durante-a-gestacao-thumb.jpeg',
    img: '/assets/blog/cuidados-durante-a-gestacao.jpeg',
    theme: 'Artigos',
    title: 'Cuidados durante a gestação',
    excerpt: `Durante a gestação acontecem muitas alterações no corpo sendo elas hormonais, ligamentares...`,
    content: `Durante a gestação acontecem muitas alterações no corpo sendo elas hormonais, ligamentares e posturais\r\nEssas alterações podem ocasionar desequilíbrio no corpo como sobrecarga na coluna, quadril e até alterações viscerais como refluxo, constipação e problemas vasculares.\r\nA Osteopatia é uma terapia manual que vai aliviar os sintomas gestacionais além de proporcionar uma melhor qualidade de vida no dia a dia de uma gestante e melhor ainda, sem uso de medicamentos!\r\nNão existe contra indicação para realizar a Osteopatia, apenas em casos de risco gestacional importante.\r\nEstá gravidinha e quer uma gestação tranquila e sem dor?\r\nAgende sua sessão!`,
    slug: `cuidados-durante-a-gestacao`,
  },
  {
    id: 3,
    cover: '/assets/blog/osteopatia-e-quiropraxia-thumb.jpeg',
    img: '/assets/blog/osteopatia-e-quiropraxia.jpeg',
    theme: 'Osteopatia e Quiropraxia',
    title: 'As funções do organismo em perfeito equibíbrio',
    excerpt: 'A Osteopatia e Quiropraxia enxergam o corpo como uma unidade, ou seja...',
    content: `A Osteopatia e Quiropraxia enxergam o corpo como uma unidade, ou seja, como um todo composto por tecidos interligados.\r\nPensando dessa forma, quando um sistema não funciona de maneira correta, há uma sobrecarga em outros pontos do nosso corpo, e a busca exata da causa do problema é o objetivo.
    \r\nEncontrar harmonia através da estimulação da cura que o próprio corpo é capaz de alcançar e, assim, devolver uma vida sem dores  aos pacientes.\r\nO tratamento realizado pela Osteopatia não tem um público específico. Qualquer pessoa pode se beneficiar, desde bebês a idosos e até mesmo  atletas de alto rendimento, tendo em vista que o objetivo é único: 
    \r\nmelhorar dores agudas e crônicas, prevenir doenças  e trazer melhora na qualidade de vida.`,
    slug: 'osteopatia-e-quiropraxia',
  },
  {
    id: 4,
    cover: '/assets/blog/instestino-preso-thumb.jpeg',
    img: '/assets/blog/instestino-preso.jpeg',
    theme: 'Artigos',
    title: 'Fatores que influênciam no mau funcionamento do intestino',
    excerpt: 'Alguns comportamentos como sedentarismo, má alimentação, pouca ingestão de água...',
    content: `Você tem intestino preso? Alguns comportamentos como sedentarismo, má alimentação, pouca ingestão de água, stress, alto consumo de remédios, entre outras causas, podem acabar piorando e ainda causar dor na lombar.\r\nA osteopatia junto com a melhora nos hábitos diários te ajudam a melhorar o funcionamento do seu intestino e aliviar sua dor.\r\nSe você tem intestino preso, agende sua sessão para uma avaliação e inicie seu tratamento!`,
    slug: 'como-melhorar-o-funcionamento-do-seu-intestino',
  },
  {
    id: 5,
    cover: '/assets/blog/cefaleia-thumb.jpeg',
    img: '/assets/blog/cefaleia.jpeg',
    theme: 'Artigos',
    title: 'Tratamento para cefaleia',
    excerpt: 'Você sabia que existem várias características diferentes da cefaleia (dor de cabeça)?',
    content: `Você sabia que existem várias características diferentes da cefaleia (dor de cabeça)?\r\nAs principais causas são: má alimentação, insônia, pouca ingestão de água, stress, problemas mecânicos e vasculares que podem causar tensões e gerar a dor.\r\nA osteopatia consegue te ajudar a regular todas essas alterações eliminando suas dores.\r\nAgende sua sessão e inicie seu tratamento.`,
    slug: 'tratamento-para-cefaleia',
  },
  {
    id: 6,
    cover: '/assets/blog/colica-thumb.jpeg',
    img: '/assets/blog/colica.jpeg',
    theme: 'Artigos',
    title: 'Como minimizar a sua cólica?',
    excerpt:
      'A cólica menstrual é causada por desequilíbrios neurológicos, hormonais, mecânicos, vasculares e essas alterações...',
    content: `A cólica menstrual é causada por desequilíbrios neurológicos, hormonais, mecânicos, vasculares e essas alterações causam muitas dores.\r\nTem sido muito comum as mulheres se queixarem desses desconfortos e a osteopatia pode ajudar no reequilíbrio e controle do nosso corpo, fazendo com que todos esses ciclos que temos mensalmente aconteçam sem sofrimento.\r\nSe você tem cólicas menstruais agende sua sessão e inicie seu tratamento!`,
    slug: 'como-minimizar-sua-colica',
  },
]

export function getAllArticles() {
  return articles
}

export function getAllSlugs() {
  let slugs = []
  getAllArticles().map(article => slugs.push(`/artigos/${article.slug}`))
  return slugs
}

export function getPostData(slug) {
  let post = null
  getAllArticles().map(article => {
    if (article.slug === slug) {
      post = article
      return
    }
  })
  return post
}
