import styled from 'styled-components'
import { token } from 'styles/theme'
import { Button } from 'components/Button'

export const Container = styled.header`
  padding: 16px;
  margin-top: 15px;

  .nav-options {
    display: flex;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 60px;
    left: -100%;
    opacity: 0;
    flex-direction: column;
    list-style-type: none;
    grid-gap: 0px;
  }
  .nav-options.active {
    background: ${token.color.primary};
    left: 0;
    opacity: 1;
    z-index: 1;
    height: 100vh;
    align-items: center;
    padding-left: 0px;
    padding-top: 8px;
    position: absolute;
    top: -16px;
    z-index: 4;
  }

  .option {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
  }
  .mobile-menu {
    display: flex;
    justify-content: right;
    position: relative;
    z-index: 4;
  }

  @media (min-width: 1200px) {
    .nav-options {
      padding: 0 calc((100% - 1280px) / 2);
      display: grid;
      left: 0;
      top: 0;
      opacity: 1;
      width: 100%;
      height: auto;
      grid-template-columns: repeat(8, auto);
      grid-gap: 5px;
      list-style-type: none;
      align-items: flex-start;
      margin: 0 auto;
      justify-content: end;
    }
    .mobile-menu {
      display: none;
    }
  }
`
export const Menu = styled.ul`
  padding-left: 0;
  display: flex;
  list-style: none;
  align-items: baseline;
`
export const MenuItem = styled.li`
  font-family: Open Sans, sans-serif;
  font-weight: 400;
  font-size: 15px;
  color: ${token.color.white};
  border: 1px solid ${token.color.white};
  border-radius: 50px;
  width: 275px;
  height: 53px;
  margin-bottom: 10px;
  cursor: pointer;
  &:first-child {
    border: none;
    margin-bottom: 60px;
  }
  @media (min-width: 992px) {
    color: ${token.color.darkPrimary};
    border: none;
    width: auto;
    height: 102px;
    &:after {
      content: '-';
      width: 1px;
      height: 0px;
      margin: 0 10px;
      color: ${token.color.primary};
      position: relative;
      top: -10px;
      left: 0px;
    }

    &:nth-last-child(-n + 2):after {
      content: '';
    }

    .option {
      height: 93px;
    }
  }

  @media (min-width: 1900px) {
    &:after {
      top: -10px;
      left: 1px;
    }
  }
`
export const MenuFixed = styled.div`
  display: none;
  @media (min-width: 992px) {
    display: block;
    position: relative;
    position: sticky;
    right: 0;
    top: -5px;
    width: 100%;
    z-index: 4;
    background-color: ${token.color.lightSecondary};
    height: 97px;
  }
`
export const MenuButton = styled(Button)`
  scroll-behavior: smooth;
`
export const ImageHeaderFixed = styled.img``
