import styled from 'styled-components'

export const SubtitleText = styled.p`
  font-size: ${props => `${props.size}px`};
  color: ${props => props.color};
  padding: ${props => (props.pd ? `${props.pd}px` : '0 16px')};
  width: 100%;
  font-weight: ${props => (props.fw ? props.fw : '400')};
  margin-top: ${props => (props.mt ? `${props.mt}px` : '0px')};
  margin-bottom: ${props => (props.mb ? `${props.mb}px` : '0px')};
  font-family: ${props => (props.family ? props.family : 'Roboto, Open Sans, sans-serif')};
  line-height: ${props => (props.lineHeight ? `${props.lineHeight}px` : 'normal')};
  text-align: ${props => (props.align ? props.align : 'left')};
  @media (min-width: 992px) {
    width: ${props => `${props.width}%`};
    text-align: ${props => (props.align ? props.align : 'left')};
  }
`
