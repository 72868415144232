export const testemonials = [
  {
    id: 2,
    text: 'Todos deveriam se consultar com a Dra. Juliana. Ela entende tudo sobre dores no corpo, eu sai muito melhor já na primeira consulta. Hoje faço acompanhamento de 15 em 15 dias e nunca mais precisei de remédios para minha dor lombar.',
    username: 'Januário Sacramento',
    titleJob: 'Professor',
  },
  {
    id: 3,
    text: 'Minha enxaqueca já era crônica. Passei por diversos médicos e ficava me entupindo de remédios que resolviam temporariamente. A Dra. Juliana conseguiu resolver as dores com seu tratamento além de ter sido muito solicita durante o mesmo!',
    username: 'Estêvão Alpuim',
    titleJob: 'Advogado',
  },
  {
    id: 4,
    text: 'Por eu ter muita dor no maxilar, procurei a ajuda da Juliana. Ela me explicou sobre o bruxismo, me indicou alguns procedimentos e hábitos para melhorar. Estou na 4 semana do tratamento e os resultados tem sido incríveis!',
    username: 'Claudia Araújo Gabeira',
    titleJob: 'Jornalista',
  },
  {
    id: 1,
    text: 'Eu já estava desistindo de curar das minhas dores nas costas. Até que finalmente encontrei a Dra. Juliana que com o tratamento de Osteopatia, hoje eu não tenho mais nenhuma queixa! Impressionante! Obrigado',
    username: 'Isabelly Macedo',
    titleJob: 'Assistente de limpeza',
  },
  {
    id: 5,
    text: 'Juliana me ajudou muito como fisioterapeuta além do suporte emocional nas fases de dor. Todo paciente que está com dor precisa de atenção, precisa ser ouvido e acolhido! Lugar super aconchegante, fácil localização e seguindo todos protocolos de segurança! Recomendo',
    username: 'Debora Odaguire',
    titleJob: 'CEO',
  },
  {
    id: 6,
    text: 'Ter um acompanhamento semanal com a Dra. Juliana Nakashima me ajudou muito a melhorar minhha performance nos treinos. Equilibrar e alinhha o corpo é fundamental para um bom desenvolvimento.',
    username: 'Carlos Rebocho',
    titleJob: 'Personal Trainer',
  },
  {
    id: 7,
    text: 'A Juliana já me tirou de muitos sufocos físicos...😅 Eu tinha crises terríveis de torcicolo e ela foi me realinhando...Ja fazem uns 2 anos que não tenho mais dores no pescoço e minha vida é outra! Super recomendo',
    username: 'Andréa Dominguez',
    titleJob: 'Nutricionista',
  },
  {
    id: 8,
    text: 'Dra. Juliana! Uma excelente profissional,  extremamente cuidadosa, uma verdadeira mãos de fadas. Super recomendo, uma clínica bem localizada e com um carinho todo especial!',
    username: 'Marlene Nobre',
    titleJob: 'Administradora de empresas',
  },
  {
    id: 9,
    text: 'Fazer osteopatia com a Juliana eliminou minhas dores no corpo. Não deixarei de ir lá !',
    username: 'Alberto Rebêlo',
    titleJob: 'Engenhheiro mecânico',
  },
  {
    id: 10,
    text: 'Atendimento excelente, recomendo muito. Certamente voltarei mais vezes.',
    username: 'Bruno Campos',
    titleJob: 'Gestor de social Media',
  },
  {
    id: 11,
    text: 'Juliana é uma excelente profissional, recomendo muito!',
    username: 'Roseli',
    titleJob: 'Fotógrafa',
  },
  {
    id: 12,
    text: 'Excelente opção de Osteopatia em São Paulo!',
    username: 'Marcelo Almeida',
    titleJob: 'Biomédico',
  },
  {
    id: 13,
    text: 'Juliana é uma ótima profissional, tem me ajudado demais a tratar minhas dores ! Já não me incomodam mais! Recomendo muito!',
    username: 'Lucca Fortino',
    titleJob: 'Programador de jogos',
  },
]
