import React from 'react'
import Slider from 'react-slick'
import { token } from 'styles/theme'
import { CardLayout } from 'components/Card/styles'
import { Container, Stars, Text, Patiente, TextLabel, UserName, CardSlider, ImageRating } from './styles'
import { testemonials } from 'constants/testemonials'
import { Separator } from 'components/Separator'
import { Subtitle } from 'components/Subtitle'
import { Title } from 'components/Title'
import { isMobile } from 'react-device-detect'

export const FeedbackCustomer = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    <Container>
      <div>
        <Subtitle
          size={15}
          color={token.color.darkGrey}
          align="center"
          width={100}
          fw="500"
          family="'Open Sans', sans-serif"
          lineHeight="22.5"
        >
          Feedbacks
        </Subtitle>
        {isMobile ? (
          <Title
            color={token.color.darkGrey}
            size="26"
            width="100"
            align="center"
            id="como-funciona-a-consulta"
            lineHeight="32"
            fw="500"
            mb="10"
            family="Roboto, sans-serif"
          >
            O que os pacientes
            <br />
            dizem sobre a consulta?
          </Title>
        ) : (
          <Title
            color={token.color.darkGrey}
            size="36"
            width="100"
            align="center"
            id="como-funciona-a-consulta"
            lineHeight="36"
            fw="500"
            mb="10"
            family="Roboto, sans-serif"
          >
            O que os pacientes
            <br />
            dizem sobre a consulta?
          </Title>
        )}
        <Separator color={token.color.darkSecondary} width={85} ml="auto" mr="auto" />
      </div>
      <Slider {...settings}>
        {testemonials.map(testemonial => {
          return (
            // eslint-disable-next-line react/jsx-key
            <CardSlider>
              <CardLayout color={token.color.white} width={93.1} height={422} key={testemonial.id} mb={40} ml={15}>
                <div>
                  <Stars>
                    <ImageRating src="/assets/star.png" alt="estrela de qualidade de serviço" width={28} height={28} />
                    <ImageRating src="/assets/star.png" alt="estrela de qualidade de serviço" width={28} height={28} />
                    <ImageRating src="/assets/star.png" alt="estrela de qualidade de serviço" width={28} height={28} />
                    <ImageRating src="/assets/star.png" alt="estrela de qualidade de serviço" width={28} height={28} />
                    <ImageRating src="/assets/star.png" alt="estrela de qualidade de serviço" width={28} height={28} />
                  </Stars>
                  <Text>{testemonial.text}</Text>
                </div>
                <UserName>
                  <Patiente>{testemonial.username}</Patiente>
                  <TextLabel>{testemonial.titleJob}</TextLabel>
                </UserName>
              </CardLayout>
            </CardSlider>
          )
        })}
      </Slider>
    </Container>
  )
}
