import { useEffect } from 'react'
import { Button } from 'components/Button'
import { token } from 'styles/theme'
import { Header } from 'components/Header'
import { HeaderFixed } from 'components/Header/HeaderFixed'
import { Footer } from 'components/Footer'
import { Title } from 'components/Title'
import { Paragraph } from 'components/Paragraph'
import { ContactInformation } from 'components/ContactInformation'
import { Whatsapp } from 'components/Whatsapp'
import { Logo } from 'components/Logo'
import {
  Heading,
  Container,
  IndicationItem,
  IndicationList,
  BackgroundImage,
  IndicationContent,
  OsteoBackground,
  TreatmentContent,
  TreatmentList,
  TreatmentItem,
  IconTreatment,
  HowItWorks,
  Interview,
  InterviewItem,
  InterviewList,
  InterviewDescription,
  TreatmentContainer,
  About,
  CompaniesListItem,
  CompaniesList,
  Graduation,
  GraduationList,
  GraduationListItem,
  JulianaImage,
  AboutContent,
  OsteSection,
  ContentOsteo,
  ImageForHome,
} from 'styles/styles'
import { Subtitle } from 'components/Subtitle'
import { Heading2 } from 'components/Title/Headings/Heading2'
import { Separator } from 'components/Separator'
import { AddressMap } from 'components/AddressMap'
import { FeedbackCustomer } from 'components/FeedbackCustomer'
import { indication } from 'constants/indication'
import { treatments } from 'constants/treatments'
import { BookAnAppointment } from 'components/BookAnAppointment'
import { Blog } from 'components/Blog'
import { interviews } from 'constants/interviews'
import { isMobile } from 'react-device-detect'
import { CardRightBorder } from 'components/Card/CardRightBorder'
import { ImageLayoutBorder } from 'components/Card/styles'
import Link from 'next/link'
import { useRouter } from 'next/router';

export default function Home() {
  const router = useRouter();

  const callOnWhatsapp = () => {

    let origin = "site";

    if ('source_campaign' in router.query) 
    {
      origin = router.query.source_campaign;
    }

    let url = "https://www.juliananakashima.com.br/whatsapp?origin="+origin;

    window.open(url, '_blank')
  }

  return (
    <div>
      <Header />
      <main>
        <BackgroundImage id="inicio">
          <Container>
            <Logo src="/assets/logo.svg" />
            <Heading>
              {
                //visão mobile
                isMobile ? (
                  <>
                    <Title color={token.color.primary} size="26" width="32" fw="500" lineHeight="32">
                      Trate a causa de suas
                      <br />
                      dores e tenha mais
                      <br />
                      qualidade de vida!
                    </Title>
                    <Paragraph color={token.color.darkGrey} size={15} width="80" mb="40" ml="auto" mr="auto">
                      Através de técnicas manuais da <strong>Osteopatia e Quiropraxia</strong>, é possível tratar a
                      causa da sua dor, equilibrando seu corpo, superando limitações físicas e restaurando sua qualidade
                      de vida, tudo isso sem o uso de remédios!
                    </Paragraph>
                  </>
                ) : (
                  //visão desktop
                  <>
                    <Title color={token.color.primary} size="36" width="128" fw="500" lineHeight="36">
                      Trate a causa de suas dores
                      <br />e tenha mais qualidade de vida!
                    </Title>
                    <Paragraph color={token.color.darkGrey} size={15} width="128" mb="60">
                      Através de técnicas manuais da <strong>Osteopatia e Quiropraxia</strong>,<br />é possível tratar a
                      causa da sua dor, equilibrando seu corpo,
                      <br />
                      superando limitações físicas e restaurando sua qualidade
                      <br />
                      de vida, tudo isso sem o uso de remédios!
                    </Paragraph>
                  </>
                )
              }

              <Button
                variant={token.color.whatsAppGreen}
                color={token.color.white}
                outline={token.color.whatsAppGreen}
                onClick={callOnWhatsapp}
                mt="80px"
              >
                Agendar consulta
              </Button>
            </Heading>
            <Whatsapp />
            <ContactInformation />
          </Container>
        </BackgroundImage>
        <HeaderFixed />
        <OsteSection id="osteopatia-e-quiropraxia">
          {
            //visão somente desktop
            !isMobile && (
              <>
                <CardRightBorder color={token.color.secondary} width={31} height={542}></CardRightBorder>
                <ImageLayoutBorder
                  image="/assets/modelo-coluna.png"
                  alt="paciente fazendo consulta"
                ></ImageLayoutBorder>
              </>
            )
          }
          <Container>
            <ContentOsteo>
              <Subtitle size={16} color={token.color.darkGrey} pd="0">
                Osteopatia e Quiropraxia
              </Subtitle>
              {isMobile ? (
                //visão mobile
                <Heading2 size={26} mt={16} width="39" lineHeight="32" align="left">
                  As funções do organismo
                  <br />
                  em perfeito equilíbrio
                  <Separator color={token.color.darkSecondary} width={120} mt="63" ml="0" mr="0" height="6" />
                </Heading2>
              ) : (
                //visão desktop
                <Heading2 size={36} mt={16} width="39" lineHeight="36" align="left">
                  As funções do organismo
                  <br />
                  em perfeito equilíbrio
                  <Separator color={token.color.darkSecondary} width={120} mt="63" ml="0" mr="0" height="6" />
                </Heading2>
              )}
              {isMobile ? (
                //visão mobile
                <Paragraph pd="0" width="80" size={15} align="left" mb="25">
                  A Osteopatia e Quiropraxia enxergam o corpo como uma unidade, ou seja,
                  <br />
                  como um todo composto por tecidos interligados...
                </Paragraph>
              ) : (
                //visão desktop
                <Paragraph pd="0" width="49" size={15}>
                  A Osteopatia e Quiropraxia enxergam o corpo como uma unidade, ou seja,
                  <br />
                  como um todo composto por tecidos interligados.
                  <br />
                  <br />
                  Pensando dessa forma, quando um sistema não funciona de maneira correta,
                  <br />
                  há uma sobrecarga em outros pontos do nosso corpo, e a busca exata da causa
                  <br />
                  do problema é o objetivo.
                  <br />
                  <br />
                  Encontrar harmonia através da estimulação da cura que o próprio corpo
                  <br />é capaz de alcançar e, assim, devolver uma vida sem dores aos pacientes.
                  <br />
                  <br />
                  O tratamento realizado pela Osteopatia não tem um público específico.
                  <br />
                  Qualquer pessoa pode se beneficiar, desde bebês a idosos e até mesmo
                  <br />
                  atletas de alto rendimento, tendo em vista que o objetivo é único:
                  <br />
                  <br />
                  <strong>
                    Melhorar dores agudas e crônicas, prevenir doenças
                    <br />e trazer melhora na qualidade de vida.
                  </strong>
                </Paragraph>
              )}

              {
                //visão somente mobile
                isMobile && (
                  <Link href="/artigos/osteopatia-e-quiropraxia">
                    <Button
                      fontSize="14"
                      variant={token.color.white}
                      color={token.color.primary}
                      outline={token.color.primary}
                      ml="16"
                      mr="0"
                      mb="60"
                      width="110"
                      height="29"
                    >
                      Saiba mais
                    </Button>
                  </Link>
                )
              }

              {
                //visão somente mobile
                isMobile && (
                  <ImageLayoutBorder
                    image="/assets/modelo-coluna.png"
                    alt="paciente fazendo consulta"
                  ></ImageLayoutBorder>
                )
              }
              {
                //visão somente mobile
                isMobile && <div id="indicacoes-e-tratamentos"></div>
              }
            </ContentOsteo>
          </Container>

          {
            //visão somente desktop
            !isMobile && <div id="indicacoes-e-tratamentos"></div>
          }
        </OsteSection>
        <OsteoBackground>
          <Container>
            <div>
              {isMobile ? (
                //visão mobile
                <Title
                  color={token.color.darkGrey}
                  size="26"
                  width="100"
                  align="center"
                  mt={46}
                  mb="8"
                  fw="500"
                  lineHeight="32"
                >
                  Quando a<br />
                  Osteopatia e Quiropraxia
                  <br />
                  são indicadas?
                </Title>
              ) : (
                //visão desktop
                <Title
                  color={token.color.darkGrey}
                  size="36"
                  width="100"
                  align="center"
                  mt={46}
                  mb="8"
                  fw="500"
                  lineHeight="36"
                >
                  Quando a Osteopatia <br /> e Quiropraxia são indicadas?
                </Title>
              )}
              {isMobile ? (
                //visão mobile
                <Subtitle size="16" color={token.color.darkGrey} align="center" width={100} lineHeight="18" fw="400">
                  Os procedimentos <br /> são indicados para o tratamento <br /> dos seguintes problemas:
                </Subtitle>
              ) : (
                //visão desktop
                <Subtitle size="16" color={token.color.darkGrey} align="center" width={100} lineHeight="20.7" fw="400">
                  Os procedimentos são indicados para o tratamento dos seguintes problemas:
                </Subtitle>
              )}

              <Separator color={token.color.darkSecondary} width={85} ml="auto" mr="auto" />
            </div>
            <IndicationContent>
              <IndicationList>
                {indication.map(indicate => {
                  return <IndicationItem key={indicate.id}>{indicate.text}</IndicationItem>
                })}
              </IndicationList>
            </IndicationContent>
          </Container>
        </OsteoBackground>
        <BookAnAppointment />
        <TreatmentContainer>
          <section>
            {isMobile ? (
              <Title color={token.color.darkGrey} size="26" width="100" align="center" fw="500" mt="69" mb="-3">
                Tratamentos
              </Title>
            ) : (
              <Title color={token.color.darkGrey} size="36" width="100" align="center" fw="500" mt="69" mb="-3">
                Tratamentos
              </Title>
            )}
            <Subtitle
              size={15}
              color={token.color.darkGrey}
              align="center"
              width={100}
              fw="400"
              lineHeight="22"
              family="'Open Sans', sans-serif"
            >
              Para cada situação existe uma técnica ideal <br /> para obtermos os melhores resultados
            </Subtitle>
            <Separator color={token.color.darkSecondary} width={85} ml="auto" mr="auto" />
            <TreatmentContent>
              <TreatmentList>
                {treatments.map(treat => {
                  return (
                    <TreatmentItem key={treat.id}>
                      <IconTreatment src={treat.url} alt={treat.name} />
                      <Subtitle
                        size={24}
                        color={token.color.white}
                        align="center"
                        width={100}
                        fw="500"
                        lineHeight="27.6"
                        family="Roboto, sans-serif"
                        mb="42"
                        mt="10"
                      >
                        {treat.name}
                      </Subtitle>
                      <Subtitle
                        size={15}
                        color={token.color.white}
                        align="center"
                        width={100}
                        fw="400"
                        lineHeight="22.5"
                        family="Open-sans, sans-serif"
                      >
                        {treat.text}
                      </Subtitle>
                    </TreatmentItem>
                  )
                })}
              </TreatmentList>
              <div id="como-funciona-a-consulta"></div>
            </TreatmentContent>
          </section>
        </TreatmentContainer>
        <HowItWorks>
          {isMobile ? (
            <Title
              color={token.color.darkGrey}
              size="26"
              width="100"
              align="center"
              mt={30}
              lineHeight="32"
              fw="500"
              mb="10"
            >
              Como funciona
              <br />a consulta?
            </Title>
          ) : (
            <Title
              color={token.color.darkGrey}
              size="36"
              width="100"
              align="center"
              mt={30}
              lineHeight="36"
              fw="500"
              mb="10"
            >
              Como funciona a consulta?
            </Title>
          )}
          <Subtitle
            size={15}
            color={token.color.darkGrey}
            align="center"
            width={100}
            fw="400"
            lineHeight="22.5"
            mb="40"
          >
            Podemos dizer que ela se baseia
            <br />
            em quatro etapas, sendo elas:
          </Subtitle>
          <Separator color={token.color.darkSecondary} width={85} ml="auto" mr="auto" />
          <Interview>
            {interviews.map(interview => {
              return (
                <InterviewList key={interview.id}>
                  <InterviewItem>
                    <Subtitle
                      size={24}
                      align="center"
                      width={100}
                      fw="500"
                      lineHeight="27.6"
                      family="Roboto, sans-serif"
                      pd="0"
                    >
                      0{interview.id}
                    </Subtitle>
                  </InterviewItem>
                  <InterviewDescription>
                    <Subtitle
                      size={24}
                      color={token.color.darkPrimary}
                      align="left"
                      width={100}
                      fw="500"
                      lineHeight="27.6"
                      family="Roboto, sans-serif"
                      mb="9"
                      mt="20"
                    >
                      {interview.title}
                    </Subtitle>
                    <Subtitle
                      size={15}
                      color={token.color.darkGrey}
                      align="left"
                      width={100}
                      lineHeight="22.5"
                      family="Open-sans, sans-serif"
                    >
                      {interview.text}
                    </Subtitle>
                  </InterviewDescription>
                </InterviewList>
              )
            })}
          </Interview>
          <div id="formacao-profissional"></div>
          <Button
            variant={token.color.whatsAppGreen}
            color={token.color.white}
            outline={token.color.whatsAppGreen}
            onClick={callOnWhatsapp}
            ml="auto"
            mr="auto"
            fontSize={18}
          >
            Quero iniciar meu tratamento!
          </Button>
        </HowItWorks>
        <About>
          {isMobile ? (
            //visão mobile
            <>
              <div id="como-funciona-a-consulta"></div>
              <AboutContent>
                <div>
                  <Title color={token.color.darkGrey} size="24" width="100" align="left" mt={30} fw="100" mb="0">
                    Dra. Juliana Nakashima
                  </Title>
                  <Subtitle
                    size={18}
                    color={token.color.darkGrey}
                    align="center"
                    width={100}
                    fw={500}
                    lineHeight="20.7"
                    mt="0"
                    pd="0"
                  >
                     Fisioterapia, Osteopatia
                    <br />e Quiropraxia
                  </Subtitle>
                </div>
                <Separator color={token.color.darkSecondary} width={85} ml="auto" mr="auto" />
                <div>
                  <CompaniesList
                    style={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(3, auto)',
                      padding: '0px 30px',
                      margin: '0 auto 40px',
                    }}
                  >
                    <CompaniesListItem>
                      <ImageForHome src="/assets/logo-eom.svg" width={75} height={75} alt="logo-eom" />
                    </CompaniesListItem>
                    <CompaniesListItem>
                      <ImageForHome
                        src="/assets/logo-andre-santos.svg"
                        width={75}
                        height={60}
                        alt="logo-andre-santos"
                      />
                    </CompaniesListItem>
                    <CompaniesListItem>
                      <ImageForHome src="/assets/logo-dante.svg" width={75} height={60} alt="logo-dante" />
                    </CompaniesListItem>
                    <CompaniesListItem>
                      <ImageForHome src="/assets/logo-ung.svg" width={75} height={55} alt="logo-ung" />
                    </CompaniesListItem>
                    <CompaniesListItem>
                      <ImageForHome src="/assets/logo-cefisa.svg" width={75} height={60} alt="logo-cefisa" />
                    </CompaniesListItem>
                    <CompaniesListItem>
                      <ImageForHome src="/assets/logo-voll.svg" width={75} height={65} alt="logo-voll-pilates" />
                    </CompaniesListItem>
                  </CompaniesList>
                </div>
                <div>
                  <JulianaImage id="formacao-profissional">
                    <ImageForHome src="/assets/juliana.svg" width="100%" height="auto" alt="juliana-nakashima" />
                  </JulianaImage>
                </div>
                <Graduation>
                  <GraduationList>
                    <GraduationListItem style={{ marginBottom: 20 }}>
                      Graduada em <strong>Fisioterapia</strong> pela Universidade Guarulhos
                    </GraduationListItem>
                    <GraduationListItem style={{ marginBottom: 20 }}>
                      Pós Graduada em <strong>Osteopatia</strong> pela Escola de Osteopatia de Madrid - EOM
                    </GraduationListItem>
                    <GraduationListItem style={{ marginBottom: 20 }}>
                      Pós Graduada em <strong>Fisioterapia Cardiorrespiratória</strong> pelo Instituto Dante Pazzanese
                      de Cardiologia
                    </GraduationListItem>                  
                    <GraduationListItem style={{ marginBottom: 20 }}>
                      Formação em <strong>Quiropraxia Clínica</strong> - Instituto André Santos
                    </GraduationListItem>
                    <GraduationListItem style={{ marginBottom: 20 }}>
                      Formação em <strong>Pilates</strong> pelo grupo VOLL
                    </GraduationListItem>
                    <GraduationListItem style={{ marginBottom: 20 }}>
                      Formação em <strong>Posturologia Osteopática</strong>
                    </GraduationListItem>
                    <GraduationListItem style={{ marginBottom: 20 }}>
                      Formação em <strong>Terapia manual</strong> - Instituto Cefisa
                    </GraduationListItem>
                  </GraduationList>
                </Graduation>
              </AboutContent>
            </>
          ) : (
            //visão desktop
            <>
              <div id="como-funciona-a-consulta">
                <JulianaImage>
                  <ImageForHome src="/assets/juliana.svg" width={765} height={649} alt="juliana-nakashima" />
                </JulianaImage>
              </div>
              <AboutContent>
                <div>
                  <Title color={token.color.darkGrey} size="36" width="100" align="left" mt={30} fw="100" mb="0">
                    Dra. Juliana Nakashima
                  </Title>
                  <Subtitle
                    size={36}
                    color={token.color.darkGrey}
                    align="left"
                    width={100}
                    fw={500}
                    lineHeight="36"
                    mt="0"
                    pd="0"
                  >
                    Fisioterapia, Osteopatia
                    <br />e Quiropraxia
                  </Subtitle>
                </div>
                <Separator color={token.color.darkSecondary} width={85} ml="0" mr="0" />
                <div>
                  <CompaniesList>
                    <CompaniesListItem>
                      <ImageForHome src="/assets/logo-eom.svg" width={75} height={75} alt="logo-eom" />
                    </CompaniesListItem>
                    <CompaniesListItem>
                      <ImageForHome
                        src="/assets/logo-andre-santos.svg"
                        width={75}
                        height={60}
                        alt="logo-andre-santos"
                      />
                    </CompaniesListItem>
                    <CompaniesListItem>
                      <ImageForHome src="/assets/logo-dante.svg" width={75} height={60} alt="logo-dante" />
                    </CompaniesListItem>
                    <CompaniesListItem>
                      <ImageForHome src="/assets/logo-ung.svg" width={75} height={55} alt="logo-ung" />
                    </CompaniesListItem>
                    <CompaniesListItem>
                      <ImageForHome src="/assets/logo-cefisa.svg" width={75} height={60} alt="logo-cefisa" />
                    </CompaniesListItem>
                    <CompaniesListItem>
                      <ImageForHome src="/assets/logo-voll.svg" width={75} height={65} alt="logo-voll-pilates" />
                    </CompaniesListItem>
                  </CompaniesList>
                </div>
                <Graduation>
                  <GraduationList>
                    <GraduationListItem>
                      Graduada em <strong>Fisioterapia</strong> pela Universidade Guarulhos
                    </GraduationListItem>
                    <GraduationListItem>
                      Pós Graduada em <strong>Osteopatia</strong> pela Escola de Osteopatia de Madrid - EOM
                    </GraduationListItem>
                    <GraduationListItem>
                      Pós Graduada em <strong>Fisioterapia Cardiorrespiratória</strong> pelo Instituto Dante Pazzanese
                      de Cardiologia
                    </GraduationListItem>
                    <GraduationListItem>
                      Formação em <strong>Quiropraxia Clínica</strong> - Instituto André Santos
                    </GraduationListItem>
                    <GraduationListItem>
                      Formação em <strong>Pilates</strong> pelo grupo VOLL
                    </GraduationListItem>
                    <GraduationListItem>
                      Formação em <strong>Posturologia Osteopática</strong>
                    </GraduationListItem>
                    <GraduationListItem>
                      Formação em <strong>Terapia manual</strong> - Instituto Cefisa
                    </GraduationListItem>
                  </GraduationList>
                </Graduation>
              </AboutContent>
            </>
          )}
        </About>
        <FeedbackCustomer />
        <AddressMap />
        {/*<Blog />*/}
      </main>
      <Footer />
    </div>
  )
}
