import styled from 'styled-components'

export const SeparatorLine = styled.div`
  background-color: ${props => props.color};
  width: ${props => `${props.width}px`};
  height: 12px;
  border-radius: 50px;
  margin-top: 25px;
  margin-bottom: 25px;
  margin-left: ${props => (props.ml ? props.ml : 'auto')};
  margin-right: ${props => (props.mr ? props.mr : 'auto')};
  @media (min-width: 500px) {
    margin-left: ${props => (props.ml ? props.ml : '0px')};
    margin-right: ${props => (props.mr ? props.mr : '0px')};
  }
`
