import React from 'react'
import { token } from 'styles/theme'
import { Button } from 'components/Button'
import { Paragraph } from 'components/Paragraph'
import { Separator } from 'components/Separator'
import { Title } from 'components/Title'
import { MapContainer, Container, Contact } from 'components/AddressMap/styles'
import { isMobile } from 'react-device-detect'

export const AddressMap = () => {
  const callOnWhatsapp = () => {
    window.open('https://www.juliananakashima.com.br/whatsapp?origin=site', '_blank')
  }

  return (
    <>
      <Container>
        {isMobile ? (
          //visão mobile
          <>
            <Contact>
              <div style={{ textAlign: 'center', marginBottom: 40 }}>
                <div style={{ marginBottom: 40 }}>
                  <Title width={100} size={24} mb={10} pd="40px 0 0" id="localizacao" fw="500" mt={0}>
                    Local de atendimento
                  </Title>
                  <label style={{ fontWeight: 400, fontSize: 15 }}>
                    A Dra.Juliana Nakashima atende em seu <br /> consultório na zona sul de São Paulo.
                  </label>
                </div>
                <Separator color={token.color.secondary} width={85} ml="auto" mr="auto" />
                <Paragraph size={18} pd={0} mb={0} mt={20} fw={500}>
                  Vila Mascote
                </Paragraph>
                <Title width={100} size={18} mb={0} mt={0} fw={500}>
                Rua Palestina, 359 - São Paulo - SP
                </Title>
                <label style={{ fontWeight: 400, fontSize: 12 }}>São Paulo - SP</label>
              </div>
            </Contact>
            <MapContainer
              id="localizacao"
              className="mapouter"
              style={{ overflow: 'hidden', background: 'none!important', height: '500px', width: '100%' }}
            >
              <div className="gmap_canvas">
                <iframe
                  width="100%"
                  height="500"
                  id="gmap_canvas"
                  style={{ overflow: 'hidden', background: 'none!important', height: '500px', width: '100%' }}
                  src="https://maps.google.com/maps?q=Rua%20Santa,%20104&t=&z=13&ie=UTF8&iwloc=&output=embed"
                  frameBorder="0"
                  scrolling="no"
                  marginHeight="0"
                  marginWidth="0"
                />
              </div>
            </MapContainer>
            <div
              id="contatos"
              style={{
                backgroundColor: `${token.color.primary}`,
                color: `${token.color.white}`,
                marginTop: -49,
                height: 320,
              }}
            >
              <div style={{ textAlign: 'center', marginBottom: 40 }}>
                <div style={{ marginBottom: 40 }}>
                  <Title width={100} size={24} mb={10} pd="40px 0 0" id="localizacao" fw="500" mt={50}>
                    Telefone e Whatsapp
                  </Title>
                  <label style={{ fontWeight: 400, fontSize: 15 }}>Entre em contato para agendar sua consulta.</label>
                </div>
                <Separator color={token.color.secondary} width={85} ml="auto" mr="auto" />
                <Paragraph size={18} pd={0} mb={0} mt={20} fw={500}>
                  11 92017-0738
                </Paragraph>
                <Button variant={token.color.whatsAppGreen} color={token.color.white} mt="31" onClick={callOnWhatsapp}>
                  Entrar em contato
                </Button>
              </div>
            </div>
          </>
        ) : (
          //visão desktop
          <>
            <Contact id="contatos">
              <div>
                <div style={{ marginBottom: 40 }}>
                  <Title width={100} size={36} mb={10} pd="0" id="localizacao" fw="500" mt={50}>
                    Local de atendimento
                  </Title>
                  <label style={{ fontWeight: 400, fontSize: 15 }}>
                    A Dra.Juliana Nakashima atende em seu consultório
                    <br />
                    na zona sul de São Paulo.
                  </label>
                </div>
                <Separator color={token.color.secondary} width={85} ml="0px" mr="0px" />
                <Paragraph size={30} pd={0} mb={10} mt={46} fw={400}>
                  Vila Mascote
                </Paragraph>
                <Title width={100} size={36} mb={0} pd="16px 0" mt={0} fw={500}>
                Rua Palestina, 359
                </Title>
                <label style={{ fontWeight: 700, fontSize: 15 }}>São Paulo - SP</label>
              </div>
              <div>
                <div style={{ marginBottom: 40 }}>
                  <Title width={100} size={36} mb={10} pd="0" fw="500" mt={50}>
                    Telefone e Whatsapp
                  </Title>
                  <label style={{ fontWeight: 400, fontSize: 15 }}>Entre em contato para agendar sua consulta.</label>
                </div>
                <Separator color={token.color.secondary} width={85} ml="0px" mr="0px" />
                <Title width={100} size={36} mb={0} pd="16px 0" fw={500}>
                  11 92017-0738
                </Title>
                <Button variant={token.color.whatsAppGreen} color={token.color.white} mt="71" onClick={callOnWhatsapp}>
                  Entrar em contato
                </Button>
              </div>
            </Contact>
            <MapContainer
              className="mapouter"
              style={{ overflow: 'hidden', background: 'none!important', height: '500px', width: '100%' }}
            >

              <div className="gmap_canvas">
                <iframe
                  width="100%"
                  height="500"
                  id="gmap_canvas"
                  style={{ overflow: 'hidden', background: 'none!important', height: '500px', width: '100%' }}
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3654.879888404488!2d-46.67150342501774!3d-23.644472164650196!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce5a82e93a4b03%3A0x5b17c671578de972!2sR.%20Palestina%2C%20359%20-%20Vila%20Mascote%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2004362-030!5e0!3m2!1spt-BR!2sbr!4v1719598234090!5m2!1spt-BR!2sbr"
                  frameBorder="0"
                  scrolling="no"
                  marginHeight="0"
                  marginWidth="0"
                />
              </div>
            </MapContainer>
          </>
        )}
      </Container>
    </>
  )
}
