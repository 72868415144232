import styled from 'styled-components'
import { token } from 'styles/theme'

export const MapContainer = styled.div``

export const Container = styled.div``

export const Contact = styled.div`
  background: url('/assets/background-atendimento.png');
  background-repeat: no-repeat;
  width: 100%;
  height: 295px;
  background-color: ${token.color.primary};
  background-size: cover;
  background-position: top;
  color: ${token.color.white};
  @media (min-width: 992px) {
    height: 438px;
    display: flex;
    justify-content: space-evenly;
  }
`
