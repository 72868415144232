import styled from 'styled-components'

export const Container = styled.div`
  margin: 24px auto;
  display: flex;
  justify-content: center;
  @media (min-width: 1200px) {
    margin: 0;
    justify-content: right;
    position: absolute;
    right: 70px;
    top: -16px;
    z-index: 2;
  }
  @media (min-width: 1900px) {
    right: 300px;
    top: -12px;
    z-index: 2;
  }
`
export const ImageLogo = styled.img``
