import React from 'react'
import { ParagraphText } from 'components/Paragraph/styles'
import PropTypes from 'prop-types'

export const Paragraph = ({ children, color, size, width, pd, mb, mt, ml, mr, align, lineHeight, fw }) => {
  return (
    <ParagraphText
      color={color}
      size={size}
      width={width}
      pd={pd}
      mb={mb}
      mt={mt}
      ml={ml}
      mr={mr}
      align={align}
      lineHeight={lineHeight}
      fw={fw}
    >
      {children}
    </ParagraphText>
  )
}

Paragraph.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  width: PropTypes.string,
  pd: PropTypes.string,
  mb: PropTypes.string,
  mt: PropTypes.string,
  ml: PropTypes.string,
  mr: PropTypes.string,
  align: PropTypes.string,
  lineHeight: PropTypes.string,
  fw: PropTypes.string,
}
