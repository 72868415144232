import React from 'react'
import { Container, ImageLogo } from 'components/Logo/styles'
import PropTypes from 'prop-types'
import { isMobile } from 'react-device-detect'

export const Logo = ({ src }) => {
  return (
    <Container>
      {isMobile ? (
        //visão mobile
        <ImageLogo src={src} alt="Juliana Nakashima Logo" width={94} height={93} />
      ) : (
        //visão desktop
        <ImageLogo src={src} alt="Juliana Nakashima Logo" width={135} height={134} />
      )}
    </Container>
  )
}

Logo.propTypes = {
  src: PropTypes.string,
}
