import React, { useState, useEffect } from 'react'
import { Container, Menu, MenuItem, MenuFixed, ImageHeaderFixed } from 'components/Header/HeaderFixed/styles'
import Link from 'next/link'

export const HeaderFixed = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const handleMenu = () => setMenuOpen(!menuOpen)
  const closeMobileMenu = () => setMenuOpen(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', function () {
        if (document.getElementById('btn-scroll').getBoundingClientRect().y < 1) {
          document.getElementById('btn-scroll').style.opacity = 1
        } else if (document.getElementById('btn-scroll').getBoundingClientRect().y > 0) {
          document.getElementById('btn-scroll').style.opacity = 0
        }
      })
    }
  }, [])

  return (
    <MenuFixed id="btn-scroll">
      <Container>
        <Menu className={menuOpen ? 'nav-options active' : 'nav-options'}>
          <MenuItem className="option" onClick={closeMobileMenu}>
            <Link href="#inicio">
              <a>Início</a>
            </Link>
          </MenuItem>
          <MenuItem className="option" onClick={closeMobileMenu}>
            <Link href="/#osteopatia-e-quiropraxia">
              <a>Osteopatia e Quiropraxia</a>
            </Link>
          </MenuItem>
          <MenuItem className="option" onClick={closeMobileMenu}>
            <Link href="/#indicacoes-e-tratamentos">
              <a>Indicações e tratamentos</a>
            </Link>
          </MenuItem>
          <MenuItem className="option" onClick={closeMobileMenu}>
            <Link href="/#formacao-profissional">
              <a>Formação profissional</a>
            </Link>
          </MenuItem>
          <MenuItem className="option" onClick={closeMobileMenu}>
            <Link href="/#como-funciona-a-consulta">
              <a>Como funciona a consulta?</a>
            </Link>
          </MenuItem>
          <MenuItem className="option" onClick={closeMobileMenu}>
            <Link href="/#localizacao">
              <a>Localização</a>
            </Link>
          </MenuItem>
          <MenuItem className="option" onClick={closeMobileMenu}>
            <Link href="/#contatos">
              <a>Contatos</a>
            </Link>
          </MenuItem>
          <MenuItem className="option" onClick={closeMobileMenu}>
            <Link href="/">
              <ImageHeaderFixed src="/assets/logo-bg-blue.svg" alt="Juliana Nakashima Logo" width={94} height={93} />
            </Link>
          </MenuItem>
        </Menu>
        <div className="mobile-menu" onClick={handleMenu}>
          {menuOpen ? (
            //visão mobile
            <ImageHeaderFixed src="/assets/close-white.svg" alt="fechar menu" width={20} height={20} />
          ) : (
            //visão desktop
            <ImageHeaderFixed src="/assets/menu.svg" alt="fechar menu" width={20} height={20} />
          )}
        </div>
      </Container>
    </MenuFixed>
  )
}
