export const treatments = [
  {
    id: 1,
    name: 'Craniana',
    url: '/assets/craniana.png',
    text: 'Concentra-se em problemas cranianos e sua influência sobre o sistema nervoso central. Com intuito de regenerar o funcionamento de articulações e tecidos cranianos.',
  },
  {
    id: 2,
    name: 'Estrutural',
    url: '/assets/estrutural.png',
    text: 'Essa técnica se dedica a restauração do sistema músculo-esquelético, postural e desajustes mecânicos das articulações, com intuito de harmonizá-la.',
  },
  {
    id: 3,
    name: 'Postural',
    url: '/assets/postural.png',
    text: 'É indicada para todas as idades e aqueles que sofrem com: má postura, hérnias discais, artroses, alterações dos pés, dores musculares, escoliose, hiperlordose, dores de cabeça.',
  },
  {
    id: 5,
    name: 'Visceral',
    url: '/assets/visceral.png',
    text: 'Atua tratamento de órgão e vísceras (estômago, intestinos, fígado, etc.) baseado em ligações anatômicas e biomecânicas, com intuito de melhorar suas funções.',
  },
]
